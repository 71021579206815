import { RefObject, useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import MobileNavigation from './MobileNavigation'
import { useHideHeaderOnScrollCondition } from './hooks/headerContext'
import { getRefDimension } from '../../../utils/util'
import useIsScrollingUp from '../../hooks/useIsScrollingUp'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize } from '../../../utils/constants'
import useHasWindowScrolledToTop from '../../../services/useHasWindowScrolledToTop'

const MobileHeader = () => {
  const [isOnTopOfPage, setIsOnTopOfPage] = useState(true)

  const { asPath } = useRouter()
  const { t } = useTranslation('url')
  const mobileTopNavigationRef = useRef<HTMLDivElement | null>(null)

  const isScrollingUp = useIsScrollingUp()
  const isOnMobileMd = useIsOnMobile(ScreenSize.md)
  const hasScrolledToTop = useHasWindowScrolledToTop()

  const headerHeight = getRefDimension('height', mobileTopNavigationRef as RefObject<HTMLElement>)

  // If user is on single product page, hide full header
  const isProductPage = asPath.includes(t('product'))

  // Detect whether user has scrolled to the top of the page
  useEffect(() => {
    if (hasScrolledToTop) {
      setIsOnTopOfPage(true)
    } else {
      setIsOnTopOfPage(false)
    }
  }, [hasScrolledToTop])

  // Pass the custom height into the hook that will hide the upper header half on scrolling
  // down, and show it again when scrolling up
  useHideHeaderOnScrollCondition(
    !isScrollingUp
    && !isOnTopOfPage
    && isOnMobileMd
    && !isProductPage, // Don't trigger on single product page, as it has own logic
    headerHeight,
  )

  return (
    <div className="box-shadow-none">
      <MobileNavigation mobileTopNavigationRef={mobileTopNavigationRef} />
    </div>
  )
}

export default MobileHeader
