import { FC } from 'react'
import { IconProps } from '@rsuite/icons/lib/Icon'

import styles from '../../styles/CartIcon.module.less'

interface CartIconProps extends IconProps {
  size?: 'xl' | 'md' | 'sm'
  hasBadgePlaceholder?: boolean
  isHeader?: boolean
}

const CartIcon: FC<CartIconProps> = ({ size = 'md', hasBadgePlaceholder }) => (
  <svg
    className={`${styles['cart-icon']} ${styles[`size-${size}`]}`}
    width="59"
    height="55"
    viewBox="0 0 59 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4534 32.0625H41.5562C43.8772 32.0625 45.0377 32.0625 45.9615 31.6298C46.7755 31.2485 47.4593 30.6364 47.928 29.8698C48.4601 28.9992 48.5882 27.8458 48.8446 25.539L50.1853 13.4733C50.2636 12.7687 50.3026 12.4164 50.1894 12.1437C50.0899 11.9042 49.9121 11.7056 49.685 11.5802C49.4265 11.4375 49.072 11.4375 48.3632 11.4375H10.3076M4.57812 4.5625H7.43913C8.04555 4.5625 8.34876 4.5625 8.586 4.67782C8.79484 4.77936 8.9679 4.94193 9.08226 5.14403C9.21215 5.37361 9.23106 5.67623 9.26889 6.28148L11.3457 39.5102C11.3835 40.1154 11.4024 40.4181 11.5323 40.6475C11.6467 40.8497 11.8197 41.0124 12.0286 41.1139C12.2658 41.2292 12.569 41.2292 13.1755 41.2292H43.5365M17.1823 49.25H17.2052M37.8073 49.25H37.8302M18.3281 49.25C18.3281 49.8827 17.8151 50.3958 17.1823 50.3958C16.5495 50.3958 16.0365 49.8827 16.0365 49.25C16.0365 48.6173 16.5495 48.1042 17.1823 48.1042C17.8151 48.1042 18.3281 48.6173 18.3281 49.25ZM38.9531 49.25C38.9531 49.8827 38.44 50.3958 37.8073 50.3958C37.1746 50.3958 36.6615 49.8827 36.6615 49.25C36.6615 48.6173 37.1746 48.1042 37.8073 48.1042C38.44 48.1042 38.9531 48.6173 38.9531 49.25Z"
      stroke="#023512"
      strokeWidth="3.54839"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {hasBadgePlaceholder && (
    <circle
      cx="49.2264"
      cy="9.22638"
      r="9.22638"
      fill="#B1F946"
    />
    )}
  </svg>

)

export default CartIcon
