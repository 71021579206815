import { FC, useEffect, useState } from 'react'
import { Button } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import { getCookie } from '../../../utils/cookies'
import BriefcaseIcon from '../../Icons/BriefcaseIcon'
import ToolIcon from '../../Icons/ToolIcon'
import HomeIcon from '../../Icons/HomeIcon'
import useModalTrigger from '../../Modals/hooks/useModalTrigger'
import { MODAL_HASHES } from '../../../utils/constants'
import DownChevronCircle from '../../Icons/DownChevronCircle'
import { CustomerType } from '../../CustomerTypeSelection'

import styles from '../../../styles/CustomerTypeSwitcher.module.less'

interface CustomerTypeSwitcherProps {
  className?: string
}

const CustomerTypeSwitcher: FC<CustomerTypeSwitcherProps> = ({ className }) => {
  const [selectedCustomerType, setSelectedCustomerType] = useState<CustomerType>('business')

  const { t } = useTranslation('common')
  const { openModalTrigger } = useModalTrigger(MODAL_HASHES.SELECT_CUSTOMER_TYPE_URL_HASH)

  const [, customerType] = getCookie('customerType') || []

  useEffect(() => {
    if (customerType) {
      setSelectedCustomerType(customerType as CustomerType)
    }
  }, [customerType])

  const renderButtonContent = () => {
    if (selectedCustomerType === 'work') {
      return (
        <>
          <span>
            <ToolIcon className={styles['tool-icon']} />
            <b>{t('My work')}</b>
          </span>
          <p>{t('Prices incl VAT')}</p>
        </>
      )
    }
    if (selectedCustomerType === 'home') {
      return (
        <>
          <span>
            <HomeIcon className={styles['home-icon']} />
            <b>{t('My home')}</b>
          </span>
          <p>{t('Prices incl VAT')}</p>
        </>
      )
    }

    return (
      <>
        <span>
          <BriefcaseIcon className={styles['briefcase-icon']} />
          <b>{t('Business')}</b>
        </span>
        <p>{t('Prices excl VAT')}</p>
      </>
    )
  }

  return (
    <>
      <Button
        appearance="link"
        className={`${styles['customer-type-switcher']} ${className}`}
        onClick={openModalTrigger}
      >
        <div>
          {renderButtonContent()}
        </div>
        <DownChevronCircle className={styles['down-chevron-circle']} />
      </Button>
    </>
  )
}

export default CustomerTypeSwitcher
